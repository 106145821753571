/* Configuration object to be passed to MSAL instance on creation.  */
export const authConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `${process.env.REACT_APP_AUTHORITY}/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/* 
 - Scopes you add here will be prompted for user consent during sign-in.
 - By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request. 
 */
export const loginRequest = {
  scopes: ["User.Read"],
  prompt: "select_account",
  forceRefresh: true, // Set forceRefresh to true to refresh the token
};

/* Add here the scopes to request when obtaining an access token for MS Graph API. */

export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_GRAPH_API,
};
