import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../../App";
import { setScrollSync } from "../../utils/scrollSync";
import { setAppError } from "../../redux/actions/appActions";
import PageWrapper from "../../components/PageWrapper";
import PageBody from "./PageBody";
import useTableUtils from "../../hooks/useTableUtils";
import { usePage } from "../../hooks/usePage";

export function Page({ business, table = 1 }) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isToolbarOpen, setToolBarOpen] = useState(false);
  const { columns } = useSelector((state) => state.app);
  const { setFilterDrawerOpen } = useContext(AppContext);
  const dispatch = useDispatch();
  const { getDataFromStore } = useTableUtils();
  const page = usePage();
  const [data] = getDataFromStore(page, table);

  useEffect(() => {
    setScrollSync();
  }, [columns, data]);

  useEffect(() => {
    setScrollSync();
    return () => {
      setFilterDrawerOpen(false);
      dispatch(setAppError(null));
    };
  }, []);

  return (
    <PageWrapper
      isDrawerOpen={isDrawerOpen}
      setDrawerOpen={setDrawerOpen}
      isToolbarOpen={isToolbarOpen}
      setToolBarOpen={setToolBarOpen}
      page={business}
    >
      <PageBody business={business} />
    </PageWrapper>
  );
}

export default Page;
