import { Tabs } from "antd";
import ProductTable from "./ProductTable";
import ApplicationTable from "./ApplicationTable";
import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePage } from "../../../hooks/usePage";
import useData from "../../../hooks/useData";
import { getFilterCookies } from "../../../utils/cookies";
import { AppContext } from "../../../App";
import { setAppError, setUpdatesOne } from "../../../redux/actions/appActions";
import { useIsAuthenticated } from "@azure/msal-react";

export const TABS_KEYS = {
  PRODUCT: "product",
  APPLICATION: "application",
};

export const DashboardBody = ({ activeTab, setActiveTab }) => {
  const { gmidMonth, gmidYear, salesYear, applicationSalesYear, applicationSalesMonth } = useSelector((state) => state.selections);
  const page = usePage();
  const { getData, getApplicationData } = useData(page);
  const dispatch = useDispatch();
  const { setFilterDrawerOpen } = useContext(AppContext);
  const isAuthenticated = useIsAuthenticated();
  const { duplicateData, duplicateApplicationData } = useSelector((state) => state.app);

  const initTableData = useCallback(
    async (tab = activeTab) => {
      const cachedFilters = getFilterCookies();
      if (!cachedFilters) {
        setFilterDrawerOpen(isAuthenticated);
      } else if (!cachedFilters?.hier2 || !cachedFilters?.hier3 || !cachedFilters?.hier5) {
        const path = window.location.pathname;
        if (path !== "/salesforce") setFilterDrawerOpen(isAuthenticated);
      } else {
        if (tab === TABS_KEYS.PRODUCT) {
          await getData({
            gmidmonth: gmidMonth,
            gmidyear: gmidYear,
            salesyear: salesYear,
            ...cachedFilters,
          });
        } else {
          await getApplicationData({
            salesyear: applicationSalesYear,
            salesmonth: applicationSalesMonth,
            ...cachedFilters,
          });
        }
      }
    },
    [
      activeTab,
      applicationSalesMonth,
      applicationSalesYear,
      getApplicationData,
      getData,
      gmidMonth,
      gmidYear,
      isAuthenticated,
      salesYear,
      setFilterDrawerOpen,
    ]
  );

  useEffect(() => {
    if (!duplicateData || duplicateData?.length === 0) initTableData();
  }, [isAuthenticated]);

  const tabs = [
    {
      key: "product",
      label: "Product Data",
      children: <ProductTable />,
    },
    {
      key: "application",
      label: "Application Data",
      children: <ApplicationTable />,
    },
  ];

  // clean-up before unmouting this component
  useEffect(() => {
    return () => {
      setFilterDrawerOpen(false);
      dispatch(setAppError(null));
      dispatch(setUpdatesOne({}));
      dispatch(setUpdatesOne({}));
    };
  }, []);

  const tabChangeHandler = useCallback(
    (tab) => {
      setActiveTab(tab);
      if (tab === TABS_KEYS.PRODUCT) {
        if (!duplicateData || duplicateData.length === 0) {
          initTableData(tab);
        }
      }
      if (tab === TABS_KEYS.APPLICATION) {
        if (!duplicateApplicationData || duplicateApplicationData.length === 0) {
          initTableData(tab);
        }
      }
    },
    [duplicateApplicationData, duplicateData, initTableData, setActiveTab]
  );

  return (
    <Tabs
      size="small"
      items={tabs}
      defaultActiveKey={activeTab}
      tabBarStyle={{ marginLeft: 45, marginTop: 5 }}
      type="card"
      onChange={tabChangeHandler}
    />
  );
};
export default DashboardBody;
