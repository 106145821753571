import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

export default function ColumnNameRenderer({ dataIndex, children }) {
  const NPSPercentageTooltipTitle = (
    <ul>
      <li>Total sum of New % and Renew % should be &gt; 0 and ≤ 100</li>
      <li>Both columns ≠ 0</li>
    </ul>
  );
  const sustPercentageTooltipTitle = "Amount entered must be between 0-100%";
  if (dataIndex === "SustnAdvgedSalesPercentage") {
    return (
      <>
        {children}&nbsp;&nbsp;
        <Tooltip title={sustPercentageTooltipTitle}>
          <InfoCircleFilled size={2} style={{ color: "gray" }} />
        </Tooltip>
      </>
    );
  } else if (dataIndex === "NPSPercentageNew" || dataIndex === "NPSPercentageRenew")
    return (
      <>
        {children}&nbsp;&nbsp;
        <Tooltip title={NPSPercentageTooltipTitle}>
          <InfoCircleFilled size={2} style={{ color: "gray" }} />
        </Tooltip>
      </>
    );
  else return <>{children}</>;
}
