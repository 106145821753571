import { Button } from "antd";
import { loginRequest } from "../config/authConfig";
import { useMsal } from "@azure/msal-react";
import { LogoutOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/actions/authActions";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { pages, usePage } from "../hooks/usePage";

const SignOutButton = ({ style, type = "default" }) => {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateCallback = useCallback((url) => navigate(url), [navigate]);
    const page = usePage();

    const handleLogout = useCallback(() => {
        if (page === pages.guidelines) navigateCallback("/");
        instance
            .logoutPopup(loginRequest)
            .then(() => {
                dispatch(updateUser(null));
            })
            .catch((e) => {
                dispatch(updateUser(null));
                console.log(e);
            });
    }, [page, navigateCallback, instance, dispatch]);

    return (
        <Button type={type} onClick={() => handleLogout()} style={style} icon={<LogoutOutlined />}>
            Sign Out
        </Button>
    );
};
export default memo(SignOutButton);
