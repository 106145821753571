export const setScrollSync = (table) => {
  const firstTable = document.querySelector(`#first-table-container-${table}`);
  const secondTable = document.querySelector(`#second-table-container-${table}`);
  if (firstTable && secondTable) {
    firstTable.addEventListener("scroll", () => {
      secondTable.scrollTop = firstTable.scrollTop;
    });
    secondTable.addEventListener("scroll", () => {
      firstTable.scrollTop = secondTable.scrollTop;
    });
  }
};
