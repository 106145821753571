import { DownOutlined, UpOutlined } from "@ant-design/icons";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../App";
import { Button } from "antd";
import SideBar from "./SideBar";
import ToolBar from "./ToolBar";
export default function PageWrapper({ isDrawerOpen, setDrawerOpen, isToolbarOpen, setToolBarOpen, page, children }) {
  const { isFilterDrawerOpen, setFilterDrawerOpen } = useContext(AppContext);
  return (
    <>
      {!isToolbarOpen && (
        <Button
          onClick={() => {
            setToolBarOpen((prevState) => !prevState);
          }}
          icon={isToolbarOpen ? <UpOutlined /> : <DownOutlined />}
          className="rotate-90 bg-gray-400 z-10 fixed top-[140px] right-0"
          style={{
            ...(isToolbarOpen ? { marginRight: 473, borderBottom: "none" } : { marginRight: -25 }),
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          Tools
        </Button>
      )}
      <div className="flex flex-col">
        <div style={{ marginTop: -200 }}>
          {!isDrawerOpen && (
            <Button
              onClick={() => {
                setFilterDrawerOpen((prevState) => !prevState);
              }}
              className="rotate-90 mt-14 bg-white z-10 fixed top-36"
              style={{
                ...(isFilterDrawerOpen ? { marginLeft: 462, borderBottom: "none" } : { marginLeft: -17 }),
                marginTop: -38,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              Filters
            </Button>
          )}
        </div>
        <div style={{ marginTop: 0 }}>
          {!isFilterDrawerOpen && (
            <Button
              onClick={() => {
                setDrawerOpen((prevState) => !prevState);
              }}
              className="rotate-90 mt-14 bg-white z-10 fixed top-36"
              style={{
                ...(isDrawerOpen
                  ? {
                      marginLeft: 510,
                      marginTop: -15,
                      borderBottom: "none",
                    }
                  : { marginLeft: -39 }),
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              Column view
            </Button>
          )}
        </div>
      </div>
      <SideBar isOpen={isDrawerOpen} setOpen={setDrawerOpen} page={page} />
      <ToolBar isOpen={isToolbarOpen} setOpen={setToolBarOpen} />
      {children}
    </>
  );
}
