import { Tooltip } from "antd";
import TableFilter from "react-table-filter";
import "react-table-filter/lib/styles.css";
import { memo, useCallback, useEffect } from "react";
import { getColumnFilterCookies } from "../utils/cookies";
import useFilterUtils from "../hooks/useFilterUtils";
import { usePage } from "../hooks/usePage";
import TimeStampRenderer from "./NPSTable/TimeStampRenderer";

function Table({
  columns,
  data,
  pageSize,
  currentPage,
  setRowsToBeUpdated,
  table,
  rowsToBeUpdated,
  setCurrentPage,
  setTableRef,
  npsTableRef,
}) {
  const page = usePage();
  const { onFilterChange } = useFilterUtils(page);

  const selectRowHandler = useCallback(
    (rowIndex) => {
      setRowsToBeUpdated((prevState) => {
        let set = new Set(prevState);
        const checkbox = document.getElementById(`check-row-${table}-${rowIndex}`);
        if (!set.has(rowIndex)) {
          set.add(rowIndex);
          checkbox.checked = true;
          return Array.from(set);
        } else {
          set.delete(rowIndex);
          checkbox.checked = false;
          return Array.from(set);
        }
      });
    },
    [setRowsToBeUpdated, table]
  );

  useEffect(() => {
    const checkBoxes = document.getElementsByClassName("row-select-check");
    for (let i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i]) checkBoxes[i].checked = false;
    }
    setTimeout(() => {
      rowsToBeUpdated?.forEach((row) => {
        const checkbox = document.getElementById(`check-row-${table}-${row}`);
        if (checkbox) checkbox.checked = true;
      });
    }, 100);
  }, [currentPage]);

  return data ? (
    <table>
      <thead>
        <TableFilter
          rows={data}
          initialFilters={getColumnFilterCookies(table)}
          onFilterUpdate={(newData, filterConfiguration) => {
            if (npsTableRef) npsTableRef?.reset(newData);
            onFilterChange(newData, filterConfiguration, setCurrentPage, table);
          }}
          ref={(node) => setTableRef(node)}
        >
          {columns
            .filter((column) => column.visibility)
            .map((column) =>
              column.visibility ? (
                <th
                  key={column.dataIndex}
                  filterkey={column.dataIndex === "select" ? null : column.dataIndex}
                  alignleft="true"
                  style={{
                    width: column?.width || 150,
                    minWidth: column?.width || 150,
                  }}
                >
                  {column.title}
                </th>
              ) : null
            )}
        </TableFilter>
      </thead>
      <tbody>
        {data &&
          data.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((row, rindex) => (
            <tr key={rindex} className={`product-data-${rindex}`}>
              {columns &&
                columns.map((column, index) =>
                  column.visibility ? (
                    column.dataIndex === "select" ? (
                      <td key={`${index - column.dataIndex}`} className="row-select">
                        <input
                          type="checkbox"
                          className={`row-select-check check-row-${table} w-4 h-4 ml-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                          style={{
                            marginTop: 10,
                            marginBottom: -20,
                          }}
                          defaultChecked={false}
                          id={`check-row-${table}-${rindex + (currentPage - 1) * pageSize}`}
                          onChange={() => {
                            selectRowHandler(rindex + (currentPage - 1) * pageSize);
                          }}
                        />
                      </td>
                    ) : column.dataIndex === "NPCDate" ? (
                      <td>
                        <TimeStampRenderer timezone={false} timestamp={row[column.dataIndex]} />
                      </td>
                    ) : (
                      <td key={index}>
                        {column.dataIndex === "NetSales" || column.dataIndex === "Margin" ? (
                          row[column.dataIndex]?.toFixed(2)
                        ) : !row[column.dataIndex] || row[column.dataIndex]?.toString().length < 20 ? (
                          row[column.dataIndex]?.toString().trim()
                        ) : (
                          <Tooltip title={row[column.dataIndex]}>
                            {row[column.dataIndex].toString().trim().substring(0, 20) + "..."}
                          </Tooltip>
                        )}
                      </td>
                    )
                  ) : null
                )}
            </tr>
          ))}
      </tbody>
    </table>
  ) : null;
}

export default memo(Table);
