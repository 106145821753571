import { store } from "../redux/store";
import { getNpsYearOptions } from "./monthUtils";

/* Please dont forget to reset the user column cache by changing localStorage 
Item named `isCleared{number}` when you change anything in the columns.

since, if user selects/deselcts columns, that config is stored in localStorage and on page reload,
user will see the same columns retrived from localStorage. */

export const productData = [
  { title: "Select", dataIndex: "select", visibility: true, width: 30, minWidth: 30 },
  { title: "Source System", dataIndex: "SourceSystem_cd", visibility: true },
  { title: "Plan Product", dataIndex: "PlanProduct_cd", visibility: true },
  { title: "Plan Product Desc", dataIndex: "PlanProduct", visibility: true },
  { title: "Trade Product", dataIndex: "TradeProduct_cd", visibility: true },
  {
    title: "Trade Product Desc",
    dataIndex: "TradeProduct",
    visibility: true,
  },
  {
    title: "Trade Product Year",
    dataIndex: "TPYear",
    visibility: true,
  },
  { title: "Material", dataIndex: "Material_cd", visibility: true },
  {
    title: "Matl Description",
    dataIndex: "Material",
    visibility: true,
  },
  {
    title: "GMID Creation Year",
    dataIndex: "GMIDYear",
    visibility: true,
  },
  {
    title: "GMID Creation Month",
    dataIndex: "GMIDMonth",
    visibility: true,
  },
  { title: "Region", dataIndex: "GELvl2", visibility: true },
  {
    title: "Matl Type",
    dataIndex: "MaterialType_cd",
    visibility: true,
  },
  {
    title: "Matl Type Desc",
    dataIndex: "MaterialType",
    visibility: true,
  },
  { title: "Profit Ctr", dataIndex: "ProfitCenter_cd", visibility: true },
  {
    title: "Profit Ctr Desc",
    dataIndex: "ProfitCenter",
    visibility: true,
  },

  {
    title: "Profit Center Lvl1",
    dataIndex: "IMRPrctrLvl1_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl1 desc",
    dataIndex: "IMRPrctrLvl1",
    visibility: false,
  },
  {
    title: "Profit Center Lvl2",
    dataIndex: "IMRPrctrLvl2_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl2 desc",
    dataIndex: "IMRPrctrLvl2",
    visibility: false,
  },
  {
    title: "Profit Center Lvl3",
    dataIndex: "IMRPrctrLvl3_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl3 Desc",
    dataIndex: "IMRPrctrLvl3",
    visibility: false,
  },
  {
    title: "Profit Center Lvl4",
    dataIndex: "IMRPrctrLvl4_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl4 Desc",
    dataIndex: "IMRPrctrLvl4",
    visibility: false,
  },
  {
    title: "Profit Center Lvl5",
    dataIndex: "IMRPrctrLvl5_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl5 Desc",
    dataIndex: "IMRPrctrLvl5",
    visibility: true,
  },
  {
    title: "Profit Center Lvl6",
    dataIndex: "IMRPrctrLvl6_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl6 Desc",
    dataIndex: "IMRPrctrLvl6",
    visibility: false,
  },
  {
    title: "Profit Center Lvl7",
    dataIndex: "IMRPrctrLvl7_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl7 Desc",
    dataIndex: "IMRPrctrLvl7",
    visibility: false,
  },
  {
    title: "Profit Center Lvl8",
    dataIndex: "IMRPrctrLvl8_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl8 Desc",
    dataIndex: "IMRPrctrLvl8",
    visibility: false,
  },
  {
    title: "Profit Center Lvl9",
    dataIndex: "IMRPrctrLvl9_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl9 Desc",
    dataIndex: "IMRPrctrLvl9",
    visibility: false,
  },

  { title: "Market Segment", dataIndex: "MSMktSegSAP_cd", visibility: true },
  {
    title: "Market Segment Desc",
    dataIndex: "MSMktSegSAP",
    visibility: true,
  },
  {
    title: "Shipping Plant",
    dataIndex: "ShippingPlant_cd",
    visibility: true,
  },
  { title: "Sales Year", dataIndex: "MXYear", visibility: true },
  { title: "Revenue ($)", dataIndex: "NetSales", visibility: true },
  { title: "Std Margin ($)", dataIndex: "Margin", visibility: true },
];

const commonTaggingColumns = [
  {
    title: "NPS New %",
    placeholder: "NPS New %",
    dataIndex: "NPSPercentageNew",
    visibility: true,
    type: "input",
    dataType: "number",
    low: 0,
    high: 100,
    required: true,
  },
  {
    title: "NPS Renew %",
    placeholder: "NPS Renew %",
    dataIndex: "NPSPercentageRenew",
    visibility: true,
    type: "input",
    dataType: "number",
    low: 0,
    high: 100,
    required: true,
  },
  {
    title: "NPS Criteria",
    dataIndex: "NPSCriteria",
    visibility: true,
    type: "dropdown",
    required: true,
    options: [
      {
        label: 2,
        value: 2,
      },
      {
        label: 3,
        value: 3,
      },
      {
        label: 4,
        value: 4,
      },
      {
        label: 5,
        value: 5,
      },
      {
        label: 6,
        value: 6,
      },
    ],
  },
  {
    title: "Sustainability Advantaged Sales",
    dataIndex: "SustnAdvgedSales",
    visibility: true,
    type: "dropdown",
    options: [
      {
        label: "YES",
        value: "YES",
      },
      {
        label: "NO",
        value: "NO",
      },
    ],
  },
  {
    title: "% Sustainability Advantaged Sales",
    placeholder: "% Sustainability Advantaged Sales",
    dataIndex: "SustnAdvgedSalesPercentage",
    visibility: true,
    type: "input",
    dataType: "number",
    low: 0,
    high: 100,
  },
  {
    title: "NPS Year/Intro Yr",
    dataIndex: "NPSYear",
    visibility: true,
    type: "dropdown",
    options: getNpsYearOptions(store.getState().selections.salesYear),
  },
  {
    title: "Project",
    dataIndex: "Project",
    visibility: true,
    type: "input",
    dataType: "text",
    width: 200,
    minWidth: 200,
  },
  {
    title: "Comment",
    dataIndex: "Commentary",
    visibility: true,
    type: "input",
    dataType: "text",
    width: 200,
    minWidth: 200,
  },
  {
    title: "Tagged By",
    dataIndex: "TaggedBy",
    visibility: true,
    type: "textView",
  },
  {
    title: "Last Modified (DD/MM/YYYY)",
    dataIndex: "lastmodifiedtimestamp",
    visibility: true,
    type: "textView",
    width: 165,
  },
];

export const npsTaggingData = [
  {
    title: "Qualify As NPS",
    dataIndex: "QualifyAsNPS",
    visibility: true,
    type: "dropdown",
    options: [
      {
        label: "YES",
        value: "YES",
      },
      {
        label: "NO",
        value: "NO",
      },
      { label: "TBD", value: "TBD" },
    ],
  },
  ...JSON.parse(JSON.stringify(commonTaggingColumns)),
];

export const applicationTagging = [
  {
    title: "Qualify As ADI",
    dataIndex: "QualifyAsApplication",
    visibility: true,
    type: "dropdown",
    options: [
      {
        label: "YES",
        value: "YES",
      },
      {
        label: "NO",
        value: "NO",
      },
      { label: "TBD", value: "TBD" },
    ],
  },
  ...JSON.parse(JSON.stringify(commonTaggingColumns)),
];

export const productCustomerData = [
  { title: "Select", dataIndex: "select", visibility: true, width: 30, minWidth: 30 },
  { title: "Source System", dataIndex: "SourceSystem_cd", visibility: true },
  { title: "Plan Product", dataIndex: "PlanProduct_cd", visibility: true },
  { title: "Plan Product Desc", dataIndex: "PlanProduct", visibility: true },
  {
    title: "Trade Product",
    dataIndex: "TradeProduct_cd",
    visibility: true,
  },
  {
    title: "Trade Product Desc",
    dataIndex: "TradeProduct",
    visibility: true,
  },
  {
    title: "Trade Product Year",
    dataIndex: "TPYear",
    visibility: true,
  },
  { title: "Material", dataIndex: "Material_cd", visibility: true },
  {
    title: "Matl Description",
    dataIndex: "Material",
    visibility: true,
  },
  {
    title: "GMID Creation Year",
    dataIndex: "GMIDYear",
    visibility: true,
  },
  {
    title: "GMID Creation Month",
    dataIndex: "GMIDMonth",
    visibility: true,
  },
  { title: "Region", dataIndex: "GELvl2", visibility: true },
  { title: "Country", dataIndex: "GELvl4", visibility: true },
  { title: "Customer", dataIndex: "GlobalCustomer_cd", visibility: true },
  { title: "Customer Desc", dataIndex: "GlobalCustomer", visibility: true },
  { title: "Market Segment", dataIndex: "MSMktSegSAP_cd", visibility: true },
  {
    title: "Market Segment Desc",
    dataIndex: "MSMktSegSAP",
    visibility: true,
  },
  {
    title: "Ship To Customer",
    dataIndex: "ShipToCustomerOriginal_cd",
    visibility: true,
  },
  {
    title: "Ship To Customer Desc",
    dataIndex: "ShipToCustomerOriginal",
    visibility: true,
  },
  {
    title: "Sold To Customer",
    dataIndex: "SoldToCustomerOriginal_cd",
    visibility: true,
  },
  {
    title: "Sold To Customer Desc",
    dataIndex: "SoldToCustomerOriginal",
    visibility: true,
  },
  { title: "Profit Ctr", dataIndex: "ProfitCenter_cd", visibility: true },
  {
    title: "Profit Ctr Desc",
    dataIndex: "ProfitCenter",
    visibility: true,
  },
  {
    title: "Profit Center Lvl1",
    dataIndex: "IMRPrctrLvl1_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl1 desc",
    dataIndex: "IMRPrctrLvl1",
    visibility: false,
  },
  {
    title: "Profit Center Lvl2",
    dataIndex: "IMRPrctrLvl2_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl2 desc",
    dataIndex: "IMRPrctrLvl2",
    visibility: false,
  },
  {
    title: "Profit Center Lvl3",
    dataIndex: "IMRPrctrLvl3_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl3 Desc",
    dataIndex: "IMRPrctrLvl3",
    visibility: false,
  },
  {
    title: "Profit Center Lvl4",
    dataIndex: "IMRPrctrLvl4_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl4 Desc",
    dataIndex: "IMRPrctrLvl4",
    visibility: false,
  },
  {
    title: "Profit Center Lvl5",
    dataIndex: "IMRPrctrLvl5_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl5 Desc",
    dataIndex: "IMRPrctrLvl5",
    visibility: true,
  },
  {
    title: "Profit Center Lvl6",
    dataIndex: "IMRPrctrLvl6_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl6 Desc",
    dataIndex: "IMRPrctrLvl6",
    visibility: false,
  },
  {
    title: "Profit Center Lvl7",
    dataIndex: "IMRPrctrLvl7_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl7 Desc",
    dataIndex: "IMRPrctrLvl7",
    visibility: false,
  },
  {
    title: "Profit Center Lvl8",
    dataIndex: "IMRPrctrLvl8_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl8 Desc",
    dataIndex: "IMRPrctrLvl8",
    visibility: false,
  },
  {
    title: "Profit Center Lvl9",
    dataIndex: "IMRPrctrLvl9_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl9 Desc",
    dataIndex: "IMRPrctrLvl9",
    visibility: false,
  },
  {
    title: "Shipping Plant",
    dataIndex: "ShippingPlant_cd",
    visibility: true,
  },
  { title: "Sales Year", dataIndex: "MXYear", visibility: true },

  { title: "Revenue", dataIndex: "NetSales", visibility: true },
  { title: "Std Margin", dataIndex: "Margin", visibility: true },
];

export const SFDCColumns = [
  { title: "Select", dataIndex: "select", visibility: true, width: 30, minWidth: 30 },
  { title: "Opportunity ID", dataIndex: "OpportunityID", visibility: true },
  {
    title: "Opportunity Name",
    dataIndex: "OpportunityName",
    visibility: true,
  },
  {
    title: "Opportunity Owner",
    dataIndex: "OpportunityOwner",
    visibility: true,
  },
  { title: "Owner Role", dataIndex: "OwnerRole", visibility: true },
  { title: "Account Name", dataIndex: "AccountName", visibility: true },
  { title: "Account Owner", dataIndex: "AccountOwner", visibility: true },
  {
    title: "Customer Segment",
    dataIndex: "CustomerSegment",
    visibility: true,
  },
  { title: "Related Account", dataIndex: "RelatedAccount", visibility: true },
  { title: "Segment L1", dataIndex: "SegmentL1", visibility: true },
  { title: "Segment L2", dataIndex: "SegmentL2", visibility: true },
  { title: "Segment L3", dataIndex: "SegmentL3", visibility: true },
  { title: "Type", dataIndex: "Type", visibility: true },
  { title: "Stage", dataIndex: "Stage", visibility: true },
  {
    title: "Probability %",
    dataIndex: "ProbabilityPercentage",
    visibility: true,
  },
  {
    title: "Owner Probability",
    dataIndex: "OwnerProbability",
    visibility: true,
  },
  { title: "Account Region", dataIndex: "AccountRegion", visibility: true },
  {
    title: "Account Geo SubRegion",
    dataIndex: "AccountGeoSubRegion",
    visibility: true,
  },
  { title: "Account Country", dataIndex: "AccountCountry", visibility: true },
  { title: "Created Date", dataIndex: "CreatedDate", visibility: true },
  { title: "Close Date", dataIndex: "CloseDate", visibility: true },
  { title: "Fiscal Period", dataIndex: "FiscalPeriod", visibility: true },
  {
    title: "Actual Commercial Date",
    dataIndex: "ActualCommercialDate",
    visibility: true,
  },
  { title: "Product Name", dataIndex: "ProductName", visibility: true },
  { title: "Product Details", dataIndex: "ProductDetails", visibility: true },
  {
    title: "Schedule History Code",
    dataIndex: "ScheduleHistoryCode",
    visibility: true,
  },
  { title: "Schedule Date", dataIndex: "ScheduleDate", visibility: true },
  { title: "Unit Price", dataIndex: "UnitPrice", visibility: true },
  { title: "Quantity", dataIndex: "Quantity", visibility: true },
  {
    title: "NNPW Schedule Revenue",
    dataIndex: "NNPWScheduleRevenue",
    visibility: true,
  },
  { title: "Owning Org", dataIndex: "OwningOrg", visibility: true },
  { title: "Program", dataIndex: "Program", visibility: true },
  { title: "Business Unit", dataIndex: "BusinessUnit", visibility: true },
  { title: "Sub Business", dataIndex: "SubBusinessUnit", visibility: true },
  { title: "Sales Year", dataIndex: "MXYear", visibility: true },
  { title: "Net Sales", dataIndex: "NetSales", visibility: true },
];

export const spectrumColumns = [
  { title: "Select", dataIndex: "select", visibility: true, width: 30, minWidth: 30 },
  { title: "Source System", dataIndex: "SourceSystem_cd", visibility: true },
  { title: "Part Number", dataIndex: "Material_cd", visibility: true },
  { title: "Part Description", dataIndex: "Material", visibility: true },
  { title: "PR Version", dataIndex: "PRVersion", visibility: true },
  {
    title: "Profit Center Lvl1 Desc",
    dataIndex: "IMRPrctrLvl1",
    visibility: false,
  },
  {
    title: "Profit Center Lvl1",
    dataIndex: "IMRPrctrLvl1_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl2",
    dataIndex: "IMRPrctrLvl2_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl2 Desc",
    dataIndex: "IMRPrctrLvl2",
    visibility: false,
  },
  {
    title: "Profit Center Lvl3",
    dataIndex: "IMRPrctrLvl3_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl3 Desc",
    dataIndex: "IMRPrctrLvl3",
    visibility: false,
  },
  {
    title: "Profit Center Lvl4",
    dataIndex: "IMRPrctrLvl4_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl4 Desc",
    dataIndex: "IMRPrctrLvl4",
    visibility: false,
  },
  {
    title: "Profit Center Lvl5",
    dataIndex: "IMRPrctrLvl5_cd",
    visibility: false,
  },
  {
    title: "Profit Center Lvl5 Desc",
    dataIndex: "IMRPrctrLvl5",
    visibility: true,
  },
  { title: "Fiscal Year", dataIndex: "MXYear", visibility: true },
  { title: "Fiscal Month", dataIndex: "MXMonth", visibility: true },
  { title: "Company Number", dataIndex: "CompanyCode_cd", visibility: true },
  { title: "Customer ID", dataIndex: "SoldToCustomerOriginal_cd", visibility: true },
  { title: "Customer Name", dataIndex: "SoldToCustomerOriginal", visibility: true },
  { title: "Site", dataIndex: "Site_cd", visibility: true },
  { title: "Site Name", dataIndex: "SiteDescription", visibility: true },
  { title: "Country", dataIndex: "Country_cd", visibility: true },
  { title: "Country Description", dataIndex: "CountryName", visibility: true },
  { title: "Market", dataIndex: "MarketCode_cd", visibility: true },
  { title: "Market Desc", dataIndex: "Market", visibility: true },
  { title: "Division", dataIndex: "Division_cd", visibility: true },
  { title: "Invoiced Amount", dataIndex: "NetSales", visibility: true },
  { title: "Margin", dataIndex: "Margin", visibility: true },
];

export const lairdColumns = [
  { title: "Select", dataIndex: "select", visibility: true, width: 30, minWidth: 30 },
  { title: "Source System", dataIndex: "SourceSystem_cd", visibility: true },
  { title: "Item", dataIndex: "Material_cd", visibility: true },
  { title: "Item Description", dataIndex: "Material", visibility: true },
  { title: "PR Version", dataIndex: "PRVersion", visibility: true },
  { title: "Profit Center Lvl1", dataIndex: "IMRPrctrLvl1_cd", visibility: false },
  { title: "Profit Center Lvl1 Desc", dataIndex: "IMRPrctrLvl1", visibility: false },
  { title: "Profit Center Lvl2", dataIndex: "IMRPrctrLvl2_cd", visibility: false },
  { title: "Profit Center Lvl2 Desc", dataIndex: "IMRPrctrLvl2", visibility: false },
  { title: "Profit Center Lvl3", dataIndex: "IMRPrctrLvl3_cd", visibility: false },
  { title: "Profit Center Lvl3 Desc", dataIndex: "IMRPrctrLvl3", visibility: false },
  { title: "Profit Center Lvl4", dataIndex: "IMRPrctrLvl4_cd", visibility: false },
  { title: "Profit Center Lvl4 Desc", dataIndex: "IMRPrctrLvl4", visibility: false },
  { title: "Profit Center Lvl5", dataIndex: "IMRPrctrLvl5_cd", visibility: false },
  { title: "Profit Center Lvl5 Desc", dataIndex: "IMRPrctrLvl5", visibility: true },
  { title: "Sales Year", dataIndex: "MXYear", visibility: true },
  { title: "Sales Month", dataIndex: "MXMonth", visibility: true },
  { title: "Sold To Customer ID", dataIndex: "SoldToCustomerOriginal_cd", visibility: true },
  { title: "Sold To Name", dataIndex: "SoldToCustomerOriginal", visibility: true },
  { title: "NPS Year", dataIndex: "NPSYear", visibility: true },
  { title: "Product Line", dataIndex: "ProductLine", visibility: true },
  { title: "Product Line Desc", dataIndex: "ProductLineDescription", visibility: true },
  { title: "Product Group", dataIndex: "ProductGroup", visibility: true },
  { title: "Product Family", dataIndex: "ProdFamily", visibility: true },
  { title: "Product Sub Family", dataIndex: "ProdSubFamily", visibility: true },
  { title: "Industry", dataIndex: "Industry", visibility: true },
  { title: "Site", dataIndex: "Site_cd", visibility: true },
  { title: "Site Desc", dataIndex: "SiteDescription", visibility: true },
  { title: "Country", dataIndex: "Country_cd", visibility: true },
  { title: "Country Name", dataIndex: "CountryName", visibility: true },
  { title: "Item Comm", dataIndex: "ItemCommCode", visibility: true },
  { title: "Item Product Line", dataIndex: "ItemProductLine", visibility: true },
  { title: "Item Product Line Desc", dataIndex: "ItemProductLineDesc", visibility: true },
  { title: "NPC Date", dataIndex: "NPCDate", visibility: true },
  { title: "NPC Code", dataIndex: "NPCCode", visibility: true },
  { title: "NPC Code Title", dataIndex: "NPCCodeTitle", visibility: true },
  { title: "Category", dataIndex: "Category", visibility: true },
  { title: "Introduced Year", dataIndex: "IntroducedYear", visibility: true },
  { title: "Invoice Sales", dataIndex: "NetSales", visibility: true },
  { title: "Margin", dataIndex: "Margin", visibility: true },
];

export const columnCategories = {
  PRODUCT_DATA: "Product Data",
  NPS_TAGGING_DATA: "NPS Tagging Data",
  PRODUCT_CUSTOMER_DATA: "Product/Customer Data",
  APPLICATION_TAGGING: "Application Tagging",
  SFDC_COLUMNS: "SFDC Columns",
  LAIRD: "LAIRD",
  SPECTRUM: "SPECTRUM",
};

export const defaultColumns = {
  [columnCategories.PRODUCT_DATA]: productData,
  [columnCategories.NPS_TAGGING_DATA]: npsTaggingData,
  [columnCategories.PRODUCT_CUSTOMER_DATA]: productCustomerData,
  [columnCategories.APPLICATION_TAGGING]: applicationTagging,
  [columnCategories.SFDC_COLUMNS]: SFDCColumns,
  [columnCategories.LAIRD]: lairdColumns,
  [columnCategories.SPECTRUM]: spectrumColumns,
};

export const COLUMN = {
  QualifyAsNPS: "QualifyAsNPS",
  NPSPercentageNew: "NPSPercentageNew",
  NPSPercentageRenew: "NPSPercentageRenew",
  NPSCriteria: "NPSCriteria",
  SustnAdvgedSales: "SustnAdvgedSales",
  SustnAdvgedSalesPercentage: "SustnAdvgedSalesPercentage",
  NPSYear: "NPSYear",
  Project: "Project",
  Commentary: "Commentary",
  TaggedBy: "TaggedBy",
  lastmodifiedtimestamp: "lastmodifiedtimestamp",
  QualifyAsApplication: "QualifyAsApplication",
};
