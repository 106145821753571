import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../App";
import { setScrollSync } from "../utils/scrollSync";
import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import SideBar from "../components/SideBar";
import ToolBar from "../components/ToolBar";
import SalesForceBody from "../components/SalesForceBody";
import { setAppError } from "../redux/actions/appActions";

function SalesForce() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isToolbarOpen, setToolBarOpen] = useState(false);
  const { salesforceData, columns } = useSelector((state) => state.app);
  const { isFilterDrawerOpen, setFilterDrawerOpen } = useContext(AppContext);
  const dispatch = useDispatch();
  useEffect(() => {
    setScrollSync();
  }, [salesforceData, columns]);
  useEffect(() => {
    setScrollSync();
    return () => {
      setFilterDrawerOpen(false);
      dispatch(setAppError(null));
    };
  }, []);
  return (
    <div>
      {!isToolbarOpen ? (
        <Button
          onClick={() => {
            setToolBarOpen((prevState) => !prevState);
          }}
          icon={isToolbarOpen ? <UpOutlined /> : <DownOutlined />}
          className="rotate-90 bg-gray-400 z-10 fixed top-[140px] right-0"
          style={{
            ...(isToolbarOpen
              ? { marginRight: 473, borderBottom: "none" }
              : { marginRight: -25 }),
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          Tools
        </Button>
      ) : null}
      <div className="flex flex-col">
        <div style={{ marginTop: -200 }}>
          {!isDrawerOpen ? (
            <Button
              onClick={() => {
                setFilterDrawerOpen((prevState) => !prevState);
              }}
              className="rotate-90 mt-14 bg-white z-10 fixed top-36"
              style={{
                ...(isFilterDrawerOpen
                  ? { marginLeft: 462, borderBottom: "none" }
                  : { marginLeft: -17 }),
                marginTop: -38,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              Filters
            </Button>
          ) : null}
        </div>
        <div style={{ marginTop: 0 }}>
          {!isFilterDrawerOpen ? (
            <Button
              onClick={() => {
                setDrawerOpen((prevState) => !prevState);
              }}
              className="rotate-90 mt-14 bg-white z-10 fixed top-36"
              style={{
                ...(isDrawerOpen
                  ? {
                      marginLeft: 510,
                      marginTop: -15,
                      borderBottom: "none",
                    }
                  : { marginLeft: -39 }),
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              Column view
            </Button>
          ) : null}
        </div>
      </div>
      <SideBar isOpen={isDrawerOpen} setOpen={setDrawerOpen} page="SFDC" />
      <ToolBar isOpen={isToolbarOpen} setOpen={setToolBarOpen} />
      <SalesForceBody />
    </div>
  );
}

export default SalesForce;
