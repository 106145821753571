import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../config/axiosClient";
import {
  setAppError,
  setUpdatesOne,
  setUpdatesTwo,
  updateApplicationData,
  updateApplicationLoading,
  updateAppLoading,
  updateData,
  updateDuplicateApplicationData,
  updateDuplicateData,
  updateDuplicateSalesForceData,
  updateProductLoading,
  updateSalesForceData,
} from "../redux/actions/appActions";
import useAggregation from "./useAggregations";
import { useCallback, useContext, useState } from "react";
import { pages } from "./usePage";
import { AppContext } from "../App";

export default function useData(page) {
  const { hasAccessToApp } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { aggregateProductData, aggregateApplicationData, aggregateSalesforceDataOnLoad } = useAggregation(page);
  const { modal } = useContext(AppContext);
  const [errorModalInstance, setErrorModalInstance] = useState(null);

  const showError = useCallback(() => {
    errorModalInstance?.destroy();
    const instance = modal.error({
      title: null,
      content: "Something went wrong, please refresh the page",
      okText: "Refresh",
      onOk: () => {
        window.location.reload();
        sessionStorage.clear();
      },
    });
    setErrorModalInstance(instance);
  }, [errorModalInstance, modal]);

  const ifError = (errorName) => sessionStorage.getItem(errorName) === "true";

  const getData = useCallback(
    async (payload) => {
      // something is causing too many re-renders if a request is failed to api calls in this file
      // below is the bypass code (not the fix).
      if (ifError("error_getData")) return;
      // bypass code ends

      dispatch(updateData(null));
      dispatch(updateProductLoading(true));
      dispatch(setAppError(null));
      dispatch(setUpdatesOne({}));

      if (!hasAccessToApp) {
        return null;
      } else {
        await axiosClient
          .post(`/getdata`, payload)
          .then((res) => {
            dispatch(updateDuplicateData(res));
            aggregateProductData(res);
            dispatch(updateProductLoading(false));
          })
          .catch((err) => {
            console.log(err);
            dispatch(setAppError(true));
            dispatch(updateProductLoading(false));
            dispatch(updateData([]));
            dispatch(updateDuplicateData([]));
            showError();
            // continuation to prev bypass code block
            sessionStorage.setItem("error_getData", true);
          });
      }
    },
    [aggregateProductData, dispatch, hasAccessToApp, showError]
  );

  const getApplicationData = useCallback(
    async (payload) => {
      if (ifError("error_getData")) return;
      dispatch(updateApplicationData(null));
      dispatch(updateApplicationLoading(true));
      dispatch(setAppError(null));
      dispatch(setUpdatesTwo({}));
      await axiosClient
        .post(`/getdata`, payload)
        .then((res) => {
          dispatch(updateDuplicateApplicationData(res));
          aggregateApplicationData(res);
          dispatch(updateApplicationLoading(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAppError(true));
          dispatch(updateApplicationLoading(false));
          dispatch(updateApplicationData([]));
          showError();
          sessionStorage.setItem("error_getData", true);
        });
    },
    [aggregateApplicationData, dispatch, showError]
  );

  const getSalesForceData = useCallback(
    async (salesYear, salesMonth = "", business = pages.liveo) => {
      if (ifError("error_getSalesData")) return;

      dispatch(updateSalesForceData(null));
      dispatch(updateDuplicateSalesForceData(null));
      dispatch(setAppError(null));
      dispatch(updateAppLoading(true));
      await axiosClient
        .post(`/getsfdc?business=${business}&sales_year=${salesYear}&sales_month=${salesMonth}`)
        .then((res) => {
          if (business === pages.liveo || business === pages.salesforce) {
            dispatch(updateDuplicateSalesForceData(res));
            aggregateSalesforceDataOnLoad(res);
          } else {
            dispatch(updateDuplicateData(res));
            aggregateProductData(res);
          }
          dispatch(updateAppLoading(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAppError(true));
          dispatch(updateAppLoading(false));
          if (business === pages.liveo || business === pages.salesforce) dispatch(updateSalesForceData([]));
          else dispatch(updateData([]));
          showError();
          sessionStorage.setItem("error_getSalesData", true);
        });
    },
    [aggregateProductData, aggregateSalesforceDataOnLoad, dispatch, showError]
  );
  return { getData, getApplicationData, getSalesForceData };
}
