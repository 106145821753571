import React from "react";
import GuidelineSection from "../../components/GuidelineSection";
import one from "./assets/NPS_decision_one.png";
import two from "./assets/NPS_decision_two.png";
import three from "./assets/reporting_criteria.png";
import four from "./assets/SASDecision.png";

function Guidelines() {
  return (
    <div className="flex flex-row items-center justify-center">
      <div className="w-[60vw] min-h-[90vh] bg-white flex flex-col py-10 px-8">
        <h2 className="w-full  flex flex-row justify-center items-center">NPS Guidelines</h2>
        <div className="flex flex-col ">
          <GuidelineSection heading={"2023 Reference"}>
            <ul className="leading-7">
              <li>The NPS metric provides an important output measure of technology and business success.</li>
              <li>
                The new product sales information is developed on an annual basis and submitted by a specified date in February to meet the
                timing of planned communications to the investment community.
              </li>
              <li>
                All criteria and definitions provided herein will be followed, and questions regarding specific issues should be referred in
                writing to Jesus Moran, the NPS process leader.
              </li>
              <li>
                A qualifying product must have been commercially introduced within the last five years and must have a unique Stock Keeping
                Unit (SKU) or equivalent.
              </li>
              <li>
                Sales from companies acquired by DuPont within the five-year window will be treated as if the Company had been part of
                DuPont for the entire five-year metric period.
              </li>
              <li>
                New product sales must be categorized as New sales or Renew sales, and it is permissible to prorate the new product's sales
                between Renew and New.
              </li>
              <li>The NPS point person should consult with the corporate NPS leader Jesus Moran for any questions.</li>
              <li>The businesses are encouraged to capture the date of launch (not just the calendar year) moving forward.</li>
              <li>Changes in raw material and "silent launches" can be included in NPS under certain conditions.</li>
              <li> Forecasted sales are not acceptable for use in NPS. </li>
              <li>Technology out-licensing is included in NPS, but trademark brand licenses do not count.</li>
            </ul>
            <img src={one} alt="one" className="w-full mt-10" />
            <img src={two} alt="two" className="w-full mt-[-5px]" />
            <img src={three} alt="three" className="w-full mt-10" />
            <img src={four} alt="four" className="w-full mt-10" />
          </GuidelineSection>
        </div>
      </div>
    </div>
  );
}

export default Guidelines;
