export const authActionTypes = {
  UPDATE_USER: `UPDATE_USER`,
  AUTH_IN_PROGRESS: `AUTH_IN_PROGRESS`,
};
export const updateUser = (user) => ({
  type: authActionTypes.UPDATE_USER,
  payload: user,
});

export const setAuthInProgress = (inProgress) => ({
  type: authActionTypes.AUTH_IN_PROGRESS,
  payload: inProgress,
});
