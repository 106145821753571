import React, { memo } from "react";
import SyncLoader from "react-spinners/SyncLoader";

function Loading() {
  return (
    <div className="w-full h-[68vh] flex flex-row justify-center items-center">
      <h2 className="font-lg ">Loading</h2>&nbsp;&nbsp;
      <SyncLoader color="#dc2626" />
    </div>
  );
}

export default memo(Loading);
