import { authActionTypes } from "../actions/authActions";

const initialState = {
  isAuthenticated: false,
  authInProgress: true,
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.UPDATE_USER:
      return { ...action.payload };
    case authActionTypes.AUTH_IN_PROGRESS:
      return { ...state, authInProgress: action.payload };
    default:
      return state;
  }
};
