import { useMsal } from "@azure/msal-react";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthInProgress, updateUser } from "../redux/actions/authActions";
import { loginRequest } from "../config/authConfig";
import { callMsGraph } from "../config/graph";
import { updateAppAccess, updateHasSFDCAccess } from "../redux/actions/appActions";

export default function useAuth() {
  const [accessToken, setAccessToken] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const { instance, inProgress, accounts } = useMsal();
  const [tokenExpiresOn, setTokenExpiresOn] = useState(false);
  const [roles, setRoles] = useState([]);
  const [idToken, setIDToken] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserProfile = async () => {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          setRoles(response?.idTokenClaims?.roles);
          setIDToken(response.idToken);
          setAccessToken(response.accessToken);
          setTokenExpiresOn(response.expiresOn);
          callMsGraph(response.accessToken).then((response) => {
            if (response) {
              setGraphData(response);
            }
          });
        })
        .catch((error) => dispatch(setAuthInProgress(false)));
    };
    getUserProfile();
  }, [instance, inProgress, accounts]);

  useEffect(() => {
    const checkUserAccess = async () => {
      if (graphData) {
        const { displayName, userPrincipalName, givenName, surname } = graphData;

        const graphEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";

        let userAvatar = null;
        window.URL.revokeObjectURL(userAvatar);
        try {
          const response = await axios(graphEndpoint, {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
          });

          if (response.status === 200) userAvatar = window.URL.createObjectURL(response.data);
        } catch (error) {
          userAvatar = null;
        }

        const userProfile = {
          isAuthenticated: true,
          user: {
            fullname: displayName,
            email: userPrincipalName,
            firstname: givenName,
            lastname: surname,
            avatar: userAvatar,
          },
          token: accessToken,
          idToken,
          roles: roles || [],
          expiresOn: moment(tokenExpiresOn).format("YYYY/MM/DD, hh:mm:ss A"),
        };
        if (roles && roles.length === 0) dispatch(updateAppAccess(false));
        if (roles?.includes("GLOBAL_IMR_RLS_PR3_INDUSTRIAL_SOLUTIONS")) {
          dispatch(updateHasSFDCAccess(true));
        }
        //window.URL.revokeObjectURL(userAvtar)
        dispatch(updateUser(userProfile));
        dispatch(setAuthInProgress(false));
      }
    };
    checkUserAccess();
  }, [graphData]);
}
