import { Button } from "antd";
import { loginRequest } from "../config/authConfig";
import { useMsal } from "@azure/msal-react";
import { LoginOutlined } from "@ant-design/icons";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setAuthInProgress } from "../redux/actions/authActions";

const SignInButton = ({ style, type = "default" }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const handleLogin = useCallback(() => {
    dispatch(setAuthInProgress(true));
    instance
      .loginPopup(loginRequest)
      .then(() => dispatch(setAuthInProgress(true)))
      .catch((e) => {
        console.log(e);
      });
  }, [dispatch, instance]);
  return (
    <Button onClick={() => handleLogin()} style={style} type={type} icon={<LoginOutlined />}>
      Sign in
    </Button>
  );
};
export default memo(SignInButton);
