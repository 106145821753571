import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppContext, TableFilterContext } from "../../../App";
import { AppError, ErrorPage } from "../../../components/ErrorPage";
import Loading from "../../../components/Loading";
import { columnCategories } from "../../../utils/columns";
import FinalTable from "../../../components/FinalTable";
import { setUpdatesTwo } from "../../../redux/actions/appActions";
import { ApplicationHeader } from "./ApplicationHeader";
import { setScrollSync } from "../../../utils/scrollSync";

function ApplicationTable() {
  const { columns, applicationData, updatesTwo, duplicateApplicationData, isApplicaitonLoading } = useSelector((state) => state.app);
  const { appError } = useSelector((state) => state.app);
  const { tableRef, tableRefTwo, setTableRefTwo, npsTableRef, npsTableRefTwo, setNPSTableRefTwo } = useContext(TableFilterContext);
  const { rowsToBeUpdatedForTableTwo, setRowsToBeUpdatedForTableTwo, pageSize, currentPageTwo, setCurrentPageTwo, setFilterDrawerOpen } =
    useContext(AppContext);

  useEffect(() => {
    setScrollSync(2);
  }, [applicationData, columns]);

  if (appError) return <AppError />;
  if (isApplicaitonLoading) return <Loading />;
  return (
    <div className="w-full px-10 mb-20">
      <ApplicationHeader npsTableRef={npsTableRef} tableRef={tableRef} />
      {duplicateApplicationData && duplicateApplicationData.length ? (
        <>
          <div className="w-full bg-white items-center shadow-xl">
            <div className="flex flex-row w-full bg-white items-center mt-3 shadow-xl px-5 pt-2">
              <h2 className="text-[1.1rem] w-[55vw]">{columnCategories.PRODUCT_CUSTOMER_DATA}</h2>
              <h2 className="text-[1.1rem] w-[36vw] flex flex-row justify-between mb-2">{columnCategories.APPLICATION_TAGGING}</h2>
            </div>
            <FinalTable
              data={applicationData}
              columns={columns[columnCategories.PRODUCT_CUSTOMER_DATA]}
              npsColumns={columns[columnCategories.APPLICATION_TAGGING]}
              setCurrentPage={setCurrentPageTwo}
              pageSize={pageSize}
              currentPage={currentPageTwo}
              setRowsToBeUpdated={setRowsToBeUpdatedForTableTwo}
              rowsToBeUpdated={rowsToBeUpdatedForTableTwo}
              updates={updatesTwo}
              setUpdates={setUpdatesTwo}
              table={2} // this is table id, set to one for product data table and set to 2 for prod/customer data
              setTableRef={setTableRefTwo}
              tableRef={tableRefTwo}
              npsTableRef={npsTableRefTwo}
              setNpsTableRef={setNPSTableRefTwo}
            />
          </div>
        </>
      ) : (
        <ErrorPage setFilterDrawerOpen={setFilterDrawerOpen} />
      )}
    </div>
  );
}

export default ApplicationTable;
