export const updateColumnCookies = (columns) => {
  localStorage.setItem("NPS_COLUMNS", JSON.stringify(columns));
};
export const getColumnCookies = () => JSON.parse(localStorage.getItem("NPS_COLUMNS"));

export const updateFilterCookies = (filters) => {
  localStorage.setItem("NPS_FILTERS", JSON.stringify(filters));
};

export const getFilterCookies = () => {
  return JSON.parse(localStorage.getItem("NPS_FILTERS"));
};

export const clearFilterCookies = () => {
  localStorage.removeItem("NPS_FILTERS");
};

export const getColumnFilterCookies = (table) => {
  return JSON.parse(localStorage.getItem(`NPS_COLUMN_FILTERS_${table}`));
};

export const setColumnFilterCookies = (filters, table) => {
  localStorage.setItem(`NPS_COLUMN_FILTERS_${table}`, JSON.stringify({ ...getColumnFilterCookies(), ...filters }));
};
export const resetColumnFilters = (table) => {
  localStorage.removeItem(`NPS_COLUMN_FILTERS_${table}`);
};

//for dev
export const clearLocalStorage = () => {
  localStorage.clear();
};

export const clearColumnFilters = () => {
  localStorage.removeItem("NPS_COLUMNS");
};

export const getSession = () => {
  let sessionVariables = {};
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    const value = sessionStorage.getItem(key);
    sessionVariables[key] = value;
  }
  return sessionVariables;
};

export const setSession = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const resetSession = () => {
  sessionStorage.clear();
};
