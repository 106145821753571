import { useIsAuthenticated } from "@azure/msal-react";
import Login from "../components/Login";
import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import { Result } from "antd";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFilterCookies, setSession } from "../utils/cookies";
import { pages, usePage } from "../hooks/usePage";

function ProtectedRoute({ children }) {
  const isAuthenticated = useIsAuthenticated();
  const { authInProgress } = useSelector((state) => state.auth);
  const { roles } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateCallback = useCallback((url) => navigate(url), [navigate]);
  const page = usePage();

  useEffect(() => {
    const filters = getFilterCookies();
    if (page === pages.SAP && filters) {
      const { hier5 } = filters;
      if (hier5 === "SPECTRUM") {
        navigateCallback("/spectrum");
      } else if (hier5 === "LAIRD PM") {
        navigateCallback("/laird");
      } else if (hier5 === "LIVEO") {
        if (filters?.dataSource === "SFDC") {
          navigateCallback("/salesforce");
        }
      } else navigateCallback("/");
    }
    return () => {
      setSession("LAST_VISITED_PAGE", page);
    };
  }, [navigateCallback, page]);

  if (!roles || (roles && roles.length === 0)) {
    return <Result status="403" title="403" subTitle="Sorry, you do not have access to this application." />;
  }
  if (authInProgress) return <Loading />;
  if (isAuthenticated) return children;
  return <Login />;
}

export default ProtectedRoute;
