import { AutoComplete, Button, Drawer, Form, Select, Space, TreeSelect } from "antd";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFilterCookies, updateFilterCookies } from "../utils/cookies";
import {
  setAppError,
  setUpdatesOne,
  setUpdatesTwo,
  updateDuplicateApplicationData,
  updateDuplicateData,
} from "../redux/actions/appActions";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import { getMonthOptions, getSelectedGmidMonths, getYearOptions, validateYear } from "../utils/monthUtils";
import { updateUserSelection } from "../redux/actions/userSelectionActions";
import useData from "../hooks/useData";
import { pages, usePage } from "../hooks/usePage";
import { FilterContext } from "../providers/FilterProvider";
import useToolbar from "../hooks/useToolbar";
import { TABS_KEYS } from "../pages/NPSDashboard/components/DashboardBody";
import { DEFAULT_MESSAGE_DURATION, LOADING_MESSAGE_DURATION } from "../constants/global.const";

const dataSourceOptions = [
  {
    label: "SAP",
    value: "SAP",
  },
  {
    label: "SFDC",
    value: "SFDC",
  },
];

function FilterPopup({ open, setOpen }) {
  const { filters } = useSelector((state) => state.app);
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const { hasAccessToApp } = useSelector((state) => state.app);
  const monthOptions = useMemo(() => getMonthOptions(), []);
  const [businessoptions, setBusinessOptions] = useState([]);
  const [loboptions, setLobOptions] = useState([]);
  const [reportingUnitOptions, setReprotingUnitOptions] = useState([]);
  const { salesYear, applicationSalesYear, applicationSalesMonth } = useSelector((state) => state.selections);
  const yearOptions = getYearOptions(salesYear);
  const page = usePage();
  const { getData, getApplicationData } = useData(page);
  const {
    businessSelected,
    setBusinessSelected,
    lobSelected,
    setLobSelected,
    reportingUnitSelected,
    setReportingUnitSelected,
    dataSourceSelected,
    setDataSourceSelected,
  } = useContext(FilterContext);

  const getGmidDisabled = React.useCallback(
    (business = reportingUnitSelected, dataSource = dataSourceSelected) =>
      (business === pages.liveo.toUpperCase() && dataSource !== "SAP") ||
      business === "LAIRD PM" ||
      business === pages.spectrum.toUpperCase(),
    [dataSourceSelected, reportingUnitSelected]
  );
  const [isGmidDisabled, setGmidDisabled] = useState(getGmidDisabled(reportingUnitSelected));

  const {
    GMIDMonthLocal,
    setGMIDMonthLocal,
    GMIDYearLocal,
    setGMIDYearLocal,
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    messageApi,
    activeTab,
  } = useContext(AppContext);
  const { resetToolbarAndUpdates } = useToolbar();

  const updateFilters = React.useCallback(() => {
    updateFilterCookies(filterForm.getFieldsValue());
  }, [filterForm]);
  const navigate = useNavigate();
  const navigateCallback = React.useCallback((url) => navigate(url), [navigate]);

  useEffect(() => {
    try {
      if (filters && !filters?.status) {
        setBusinessOptions(
          Object.keys(filters)
            .map((value) => {
              return {
                label: value,
                value: value,
              };
            })
            .sort((a, b) => (a.value < b.value ? -1 : 1))
        );
        if (filters && businessSelected) {
          setLobOptions(
            Object.keys(filters[businessSelected])
              .map((value) => {
                return {
                  label: value,
                  value: value,
                };
              })
              .sort((a, b) => (a.value < b.value ? -1 : 1))
          );
          setReprotingUnitOptions(filters[businessSelected][lobSelected]);
        }
      }
    } catch (err) {
      clearFilterCookies();
      dispatch(setAppError(true));
      setOpen(true);
    }
  }, [businessSelected, dispatch, filters, lobSelected, open, setOpen]);

  const navigateUtil = React.useCallback(
    (filters) => {
      const { hier5 } = filters;
      if (hier5 && hier5 === pages.liveo.toUpperCase() && filters.dataSource === "SFDC") {
        navigateCallback("/salesforce");
        return true;
      } else if (hier5 && hier5 === pages.spectrum.toUpperCase()) {
        navigateCallback("/spectrum");
        return true;
      } else if (hier5 && hier5 === "LAIRD PM") {
        navigateCallback("/laird");
        return true;
      } else navigateCallback("/");
      return false;
    },
    [navigateCallback]
  );

  const onFinishHandler = React.useCallback(async () => {
    setOpen(false);
    updateFilters();
    resetToolbarAndUpdates();
    dispatch(updateUserSelection({ gmidMonth: GMIDMonthLocal, gmidYear: GMIDYearLocal }));
    const filters = filterForm.getFieldsValue();
    const dataSource = filters["dataSource"] || "SAP";
    const checkBoxes = document.getElementsByClassName("row-select-check");

    for (let i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i]) checkBoxes[i].checked = false;
    }
    setRowsToBeUpdated([]);
    setRowsToBeUpdatedForTableTwo([]);
    dispatch(setUpdatesOne({}));
    dispatch(setUpdatesTwo({}));
    dispatch(updateDuplicateData(null));
    dispatch(updateDuplicateApplicationData(null));

    if (navigateUtil(filters)) {
      return;
    }
    if (dataSource === "SAP") {
      navigateCallback("/");
      messageApi.open({
        type: "loading",
        content: "Applying filters, please wait",
        duration: LOADING_MESSAGE_DURATION,
        key: "filters",
      });
      if (activeTab === TABS_KEYS.PRODUCT) {
        await getData({
          gmidmonth: GMIDMonthLocal,
          gmidyear: GMIDYearLocal,
          salesyear: salesYear,
          ...filters,
        });
      } else {
        await getApplicationData({
          salesyear: applicationSalesYear,
          salesmonth: applicationSalesMonth,
          ...filters,
        });
      }

      messageApi.open({
        key: "filters",
        type: "success",
        duration: DEFAULT_MESSAGE_DURATION,
        content: "Filters applied",
      });
    } else {
      navigateCallback("/salesforce");
    }
  }, [
    setOpen,
    updateFilters,
    resetToolbarAndUpdates,
    dispatch,
    GMIDMonthLocal,
    GMIDYearLocal,
    filterForm,
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    navigateUtil,
    navigateCallback,
    messageApi,
    activeTab,
    getData,
    salesYear,
    getApplicationData,
    applicationSalesYear,
    applicationSalesMonth,
  ]);

  const businessChangeHandler = React.useCallback(
    (value) => {
      setBusinessSelected(value);
      filterForm.setFieldValue("hier5", null);
      filterForm.setFieldValue("hier3", null);
      setLobOptions(
        Object.keys(filters[value]).map((value) => {
          return {
            label: value,
            value: value,
          };
        })
      );
    },
    [filterForm, filters, setBusinessSelected]
  );

  const lobChangeHandler = React.useCallback(
    (value) => {
      filterForm.setFieldValue("hier5", null);
      setReprotingUnitOptions(filters[businessSelected][value].sort((a, b) => (a.value < b.value ? -1 : 1)));
      setLobSelected(value);
    },
    [businessSelected, filterForm, filters, setLobSelected]
  );

  const reportingunitChangehandler = React.useCallback(
    (value) => {
      setReportingUnitSelected(value);
      setGmidDisabled(() => getGmidDisabled(value));
    },
    [getGmidDisabled, setReportingUnitSelected]
  );

  const dataSoruceChangeHandler = React.useCallback(
    (value) => {
      setDataSourceSelected(value);
      setGmidDisabled(() => getGmidDisabled(reportingUnitSelected, value));
    },
    [getGmidDisabled, reportingUnitSelected, setDataSourceSelected]
  );

  const filterStatus = useMemo(() => {
    if (filters?.status)
      messageApi.open({
        key: "filters",
        type: "error",
        duration: DEFAULT_MESSAGE_DURATION,
        content: "Something went wrong, please try again.",
      });
    return !filters ? "loading" : filters?.status;
  }, [filters, messageApi]);

  return (
    <Drawer
      placement="left"
      rootClassName="filter-drawer"
      open={hasAccessToApp && open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      closeable={false}
      closeIcon={null}
      maskClosable={false}
      destroyOnClose={true}
      width={480}
      mask={false}
      style={{ margin: 0, padding: 0 }}
    >
      <Form form={filterForm} layout="vertical" onFinish={onFinishHandler}>
        <h1 className="bold text-red-600">Welcome to the NPS Tagging App!</h1>
        <span>Please select and confirm the following to continue&nbsp;:</span>
        {filterStatus === "error" ? <h3 className="bold text-red-600">Something went wrong, please refresh the app</h3> : null}
        <div className="flex flex-col gap-3">
          <Form.Item
            label={<h4>Business</h4>}
            name="hier2"
            rules={[
              {
                required: true,
                message: "Business is required",
              },
            ]}
            initialValue={businessSelected}
            style={{ marginBottom: -10 }}
          >
            <Select
              options={businessoptions}
              loading={filterStatus === "loading"}
              status={filterStatus === "error" ? "error" : null}
              onChange={businessChangeHandler}
              placeholder="Business"
              style={{ marginTop: -25 }}
              showSearch
            />
          </Form.Item>
          <Form.Item
            label={<h4>Line of Business</h4>}
            name="hier3"
            rules={[
              {
                required: true,
                message: "Line of Business is required",
              },
            ]}
            initialValue={lobSelected}
            style={{ marginBottom: -5 }}
          >
            <Select
              options={loboptions}
              loading={filterStatus === "loading"}
              status={filterStatus === "error" ? "error" : null}
              placeholder="Line of Business"
              style={{ marginTop: -25 }}
              showSearch
              onChange={lobChangeHandler}
            />
          </Form.Item>
          <Form.Item
            label={<h4>Reporting Unit</h4>}
            name="hier5"
            rules={[
              {
                required: true,
                message: "Reporting Unit is required",
              },
            ]}
            initialValue={reportingUnitSelected}
            style={{ marginBottom: -5 }}
          >
            <Select
              loading={filterStatus === "loading"}
              status={filterStatus === "error" ? "error" : null}
              options={reportingUnitOptions}
              placeholder="Reporting Unit"
              style={{ marginTop: -25 }}
              showSearch
              onChange={reportingunitChangehandler}
            />
          </Form.Item>
          {reportingUnitSelected === pages.liveo.toUpperCase() ? (
            <Form.Item
              label={<h4>Data source</h4>}
              name="dataSource"
              rules={[
                {
                  required: true,
                  message: "Data source is required",
                },
              ]}
              initialValue={dataSourceSelected}
              style={{ marginBottom: -5 }}
            >
              <Select
                options={dataSourceOptions}
                value={dataSourceSelected}
                onChange={dataSoruceChangeHandler}
                placeholder="Data source"
                style={{ marginTop: -25 }}
                showSearch
              />
            </Form.Item>
          ) : null}
          <Form.Item
            label={
              <h4 className="flex flex-row items-center">
                {!isGmidDisabled ? <span className="text-red-400 text-lg">*</span> : null}
                &nbsp; Material Created Date{" "}
              </h4>
            }
            rules={[
              {
                required: !isGmidDisabled,
              },
            ]}
            style={{ marginTop: 0 }}
          >
            <Space direction="horizontal" style={{ marginTop: "-100px" }} className="w-full">
              <span className="w-1/2">
                &nbsp; GMID Year :
                <AutoComplete
                  status={validateYear(GMIDYearLocal) ? "" : "error"}
                  disabled={isGmidDisabled}
                  style={{ width: 225 }}
                  placeholder="Year"
                  options={yearOptions}
                  value={GMIDYearLocal}
                  onChange={(year) => {
                    setGMIDYearLocal(year);
                  }}
                />
              </span>
              <span className="w-1/2">
                &nbsp;GMID Month:
                <TreeSelect
                  style={{ width: 225, height: 30, overflow: "clip" }}
                  placeholder="Month"
                  value={GMIDMonthLocal}
                  disabled={isGmidDisabled}
                  treeData={monthOptions}
                  treeCheckable
                  treeDefaultExpandAll
                  treeIcon={true}
                  onChange={(month) => {
                    const _month = getSelectedGmidMonths(month);
                    setGMIDMonthLocal(_month);
                  }}
                />
              </span>
            </Space>
          </Form.Item>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-red-500"> * Required fields</span>
          <Button type="primary" htmlType="submit">
            Apply Filters
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default memo(FilterPopup);
