import { Button } from "antd";
import React, { useContext, useState } from "react";
import SideBar from "../../components/SideBar";
import { useSelector } from "react-redux";
import { AppContext } from "../../App";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import ToolBar from "../../components/ToolBar";
import DashboardBody from "./components/DashboardBody";

function NPSDashboard() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isToolbarOpen, setToolBarOpen] = useState(false);
  const { hasAccessToApp } = useSelector((state) => state.app);
  const { isFilterDrawerOpen, setFilterDrawerOpen, activeTab, setActiveTab } = useContext(AppContext);

  return (
    <div>
      {!isToolbarOpen && hasAccessToApp ? (
        <Button
          onClick={() => {
            setToolBarOpen((prevState) => !prevState);
          }}
          icon={isToolbarOpen ? <UpOutlined /> : <DownOutlined />}
          className="rotate-90 bg-gray-400 z-10 fixed top-[140px] right-0 "
          style={{
            ...(isToolbarOpen ? { marginRight: 473, borderBottom: "none" } : { marginRight: -25 }),
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          Tools
        </Button>
      ) : null}
      {hasAccessToApp ? (
        <div className="flex flex-col">
          <div style={{ marginTop: -200 }}>
            {!isDrawerOpen ? (
              <Button
                onClick={() => {
                  setFilterDrawerOpen((prevState) => !prevState);
                }}
                className="rotate-90 mt-14 bg-white z-10 fixed top-36"
                style={{
                  ...(isFilterDrawerOpen ? { marginLeft: 462, borderBottom: "none" } : { marginLeft: -17 }),
                  marginTop: -38,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                Filters
              </Button>
            ) : null}
          </div>
          <div style={{ marginTop: -200 }}>
            {!isFilterDrawerOpen ? (
              <Button
                onClick={() => {
                  setDrawerOpen((prevState) => !prevState);
                }}
                className="rotate-90 mt-14 bg-white z-10 fixed top-36"
                style={{
                  ...(isDrawerOpen
                    ? {
                        marginLeft: 510,
                        marginTop: -15,
                        borderBottom: "none",
                      }
                    : { marginLeft: -39 }),
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                Column view
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
      {hasAccessToApp ? (
        <>
          <SideBar isOpen={isDrawerOpen} setOpen={setDrawerOpen} activeTab={activeTab} />
          <ToolBar isOpen={isToolbarOpen} setOpen={setToolBarOpen} />
        </>
      ) : null}
      <DashboardBody setActiveTab={setActiveTab} activeTab={activeTab} />
    </div>
  );
}

export default NPSDashboard;
