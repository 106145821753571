import { useState } from "react";

export function useDebounce() {
  const [lastTimeout, setLastTimeout] = useState(null);

  const debounce = (func, delay = 1500) => {
    if (lastTimeout) clearTimeout(lastTimeout);

    const timeout = setTimeout(() => {
      func();
    }, delay);
    setLastTimeout(timeout);
  };

  return { debounce,lastTimeout };
}
export default useDebounce;
