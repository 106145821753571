import React, { useContext, useEffect } from "react";
import { AppContext, TableFilterContext } from "../../App";
import { Button, Select, TreeSelect } from "antd";
import { getMonthOptions, getSelectedGmidMonths, getYearOptions } from "../../utils/monthUtils";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { AccessNotFound, AppError, ErrorPage } from "../../components/ErrorPage";
import { updateUserSelection } from "../../redux/actions/userSelectionActions";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import useTagging from "../../hooks/useTagging";
import FinalTable from "../../components/FinalTable";
import { columnCategories } from "../../utils/columns";
import useData from "../../hooks/useData";
import useFilterUtils from "../../hooks/useFilterUtils";
import { setUpdatesOne } from "../../redux/actions/appActions";
import { getColumnFilterCookies } from "../../utils/cookies";
import { pages, usePage } from "../../hooks/usePage";
import useDebounce from "../../hooks/useDebounce";
import useToolbar from "../../hooks/useToolbar";

function PageBody({ business, table = 1 }) {
  const yearOptions = getYearOptions();
  const { salesYearSf, salesMonthSf } = useSelector((state) => state.selections);
  const { columns, appError, updatesOne, isAppLoading } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { data, duplicateData } = useSelector((state) => state.app); //table is 1 for product data table and 2 for prod/customer data
  const { tableRef, setTableRef, npsTableRef, setNPSTableRef } = useContext(TableFilterContext);
  const { rowsToBeUpdated, setRowsToBeUpdated, pageSize, currentPage, setCurrentPage, setFilterDrawerOpen } = useContext(AppContext);
  const { token } = useSelector((state) => state.auth);
  const monthOptions = getMonthOptions();
  const { debounce, lastTimeout } = useDebounce();
  const page = usePage();
  const { submitSalesforceTagging } = useTagging(page);
  const { clearProductFilters } = useFilterUtils(page);
  const { getSalesForceData } = useData(page);
  const { resetToolbarAndUpdates } = useToolbar();

  useEffect(() => {
    if (token) {
      getSalesForceData(salesYearSf, salesMonthSf, business);
    }
  }, [business, token]);

  const yearMonthChangeHandler = React.useCallback(
    (year = salesYearSf, month = salesMonthSf) => {
      if (month.length === 0) return;
      if (lastTimeout) clearTimeout(lastTimeout);
      debounce(() => getSalesForceData(year, month, business));
      resetToolbarAndUpdates();
    },
    [business, debounce, getSalesForceData, lastTimeout, resetToolbarAndUpdates, salesMonthSf, salesYearSf]
  );

  if (isAppLoading) return <Loading />;
  if (data?.status === 403) return <AccessNotFound />;
  if (appError) return <AppError />;

  return (
    <div className="w-full px-10 mb-20" style={{ marginTop: 20 }}>
      <div className="flex flex-row justify-between items-center mb-3">
        <span className="text-red-500 font-normal text-3xl ml-5">NPS Evaluation</span>
        <div className="flex gap-2 items-center">
          <>
            <span>{business === pages.spectrum ? "Fiscal Year" : "Sales Year"}</span>
            <Select
              style={{ width: 137, height: 30 }}
              placeholder="Sales Year"
              options={yearOptions.slice(0, 2)}
              value={salesYearSf}
              onChange={(year) => {
                dispatch(updateUserSelection({ salesYearSf: year }));
                yearMonthChangeHandler(year, salesMonthSf);
              }}
            />
          </>
          <>
            <span>{business === pages.spectrum ? "Fiscal Month" : "Sales Month"}</span>
            <TreeSelect
              style={{ width: 137, height: 30, overflow: "clip" }}
              placeholder="Month"
              value={salesMonthSf}
              treeData={monthOptions}
              treeCheckable
              treeDefaultExpandAll
              treeIcon={true}
              onChange={(month) => {
                const _month = getSelectedGmidMonths(month);
                dispatch(updateUserSelection({ salesMonthSf: _month }));
                yearMonthChangeHandler(salesYearSf, _month);
              }}
            />
          </>
        </div>
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => clearProductFilters(tableRef, npsTableRef, duplicateData, currentPage)}
            disabled={!getColumnFilterCookies(1)}
            type="default"
            icon={<DeleteOutlined />}
          >
            Clear Filters
          </Button>
          <Button
            onClick={() => {
              submitSalesforceTagging(business);
            }}
            type="default"
            icon={<CheckOutlined />}
          >
            Save
          </Button>
        </div>
      </div>
      {duplicateData && duplicateData.length ? (
        <>
          <div className="w-full bg-white items-center shadow-xl">
            <div className="flex flex-row w-full bg-white items-center mt-0 shadow-xl px-5 pt-2">
              <h2 className="text-[1.1rem] w-[55vw]">Product Data</h2>
              <h2 className="text-[1.1rem] w-[36vw] flex flex-row justify-between mb-2">{columnCategories.NPS_TAGGING_DATA}</h2>
            </div>
            <FinalTable
              data={data}
              columns={columns[business.toUpperCase()]}
              npsColumns={columns[columnCategories.NPS_TAGGING_DATA]}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              currentPage={currentPage}
              setRowsToBeUpdated={setRowsToBeUpdated}
              rowsToBeUpdated={rowsToBeUpdated}
              updates={updatesOne}
              setUpdates={setUpdatesOne}
              table={table} // this is table id, set to one for product data table and set to 2 for prod/customer data
              setTableRef={setTableRef}
              tableRef={tableRef}
              npsTableRef={npsTableRef}
              setNpsTableRef={setNPSTableRef}
            />
          </div>
        </>
      ) : (
        <ErrorPage setFilterDrawerOpen={setFilterDrawerOpen} />
      )}
    </div>
  );
}

export default React.memo(PageBody);
