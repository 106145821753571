import moment from "moment-timezone";
import { memo, useMemo } from "react";

function TimeStampRenderer({ timestamp, timezone = true }) {
  const getLocalizedTime = useMemo(() => {
    if (timestamp) {
      if (timezone) {
        const format = `DD/MM/YYYY, hh:mm:ss A`;
        return moment.utc(timestamp).local().format(format);
      } else {
        const format = `DD/MM/YYYY, hh:mm:ss`;
        return moment.utc(timestamp).format(format);
      }
    }
    return null;
  }, [timestamp, timezone]);

  return <>{getLocalizedTime}</>;
}

export default memo(TimeStampRenderer);
