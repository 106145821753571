import React, { useEffect, useContext } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "../components/Header";
import { routeArray } from "./routeArray";
import ProtectedRoute from "./ProtectedRoute";
import NavBar from "../components/NavBar";
import { updateColumns } from "../redux/actions/appActions";
import { defaultColumns } from "../utils/columns";
import { useDispatch, useSelector } from "react-redux";
import { getColumnCookies } from "../utils/cookies";
import { AppContext } from "../App";
import Login from "../components/Login";
import Loading from "../components/Loading";
import FilterPopup from "../components/FilterPopup";
import { AppError } from "../components/ErrorPage";
import { AccessNotFound } from "../components/ErrorPage";

function Routing() {
  const dispatch = useDispatch();
  const { isFilterDrawerOpen, setFilterDrawerOpen } = useContext(AppContext);
  const {
    auth: { isAuthenticated, authInProgress },
    app: { hasAccessToApp },
  } = useSelector((state) => state);
  const { appError } = useSelector((state) => state.app);

  useEffect(() => {
    let cachedColumns = getColumnCookies();
    if (cachedColumns) dispatch(updateColumns(cachedColumns));
    else dispatch(updateColumns(defaultColumns));
  }, []);

  return (
    <Router>
      <Header />
      <FilterPopup open={isFilterDrawerOpen} setOpen={setFilterDrawerOpen} />
      {isAuthenticated ? <NavBar /> : null}
      {authInProgress ? (
        <Loading />
      ) : !isAuthenticated ? (
        <Login />
      ) : appError ? (
        <AppError />
      ) : !hasAccessToApp ? (
        <AccessNotFound appAccess />
      ) : (
        <Routes>
          {routeArray.map((route, idx) => (
            <Route key={idx} path={route.path} element={<ProtectedRoute>{route.component}</ProtectedRoute>} />
          ))}
        </Routes>
      )}
    </Router>
  );
}

export default Routing;
