export const yearRegex = new RegExp(/^[0-9]{4}$/);

export const getYearOptions = (initialYear = new Date().getFullYear()) => {
  let array = [];
  for (let i = 0; i < 5; i++) {
    let year = initialYear - i;
    array.push({
      value: year,
      label: year,
    });
  }
  return array;
};

export const validateYear = (year) => {
  if (year === "") return false;
  if (!yearRegex.test(year)) return false;
  if (year > new Date().getFullYear()) return false;
  return true;
};

const months = [
  { key: "001", value: "001", title: "January" },
  { key: "002", value: "002", title: "February" },
  { key: "003", value: "003", title: "March" },
  { key: "004", value: "004", title: "April" },
  { key: "005", value: "005", title: "May" },
  { key: "006", value: "006", title: "June" },
  { key: "007", value: "007", title: "July" },
  { key: "008", value: "008", title: "August" },
  { key: "009", value: "009", title: "September" },
  { key: "010", value: "010", title: "October" },
  { key: "011", value: "011", title: "November" },
  { key: "012", value: "012", title: "December" },
];

export const getMonthOptions = (year) => {
  return [
    {
      title: "Select all",
      key: "selectall",
      value: months.map((month) => month.value).join(","),
      children: months,
    },
  ];
};

export const monthDict = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012"];

export const getDefaultGMidMonthYear = () => {
  const date = new Date();
  let currentMonth = date.getMonth();
  let month = currentMonth === 0 ? 11 : currentMonth - 1;
  let year = currentMonth === 0 ? date.getFullYear() - 1 : date.getFullYear();
  return {
    year: year,
    month: monthDict[month],
  };
};

export const getNpsYearOptions = (salesYear) => {
  let array = [];
  let year = salesYear - 4;
  for (let i = 0; i < 9; i++) {
    array.push({ value: year, label: year });
    year++;
  }
  return array;
};

export const getSelectedGmidMonths = (month) => {
  return month.reduce((acc, cur) => {
    let _cur = cur.split(",");
    return Array.from(new Set(acc.concat(_cur)));
  }, []);
};
