export const pages = {
  liveo: "liveo",
  salesforce: "salesforce",
  laird: "laird",
  spectrum: "spectrum",
  SAP: "SAP",
  guidelines: "guidelines",
};

export function usePage() {
  const path = window.location.pathname;
  if (path === "/salesforce") return pages.salesforce;
  else if (path === "/laird") return pages.laird;
  else if (path === "/spectrum") return pages.spectrum;
  else if (path === "/") return pages.SAP;
  else if (path === "/guidelines") return pages.guidelines;
}
