import { legacy_createStore as createStore, applyMiddleware, combineReducers } from "redux";
import { authReducer } from "./reducers/authReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { appReducer } from "./reducers/appReducer";
import { userSelections } from "./reducers/userSelectionReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  selections: userSelections,
});
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export const DataProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
