import { Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useState } from "react";
import { runes } from "runes2";
import { COLUMN } from "../../utils/columns";

export function CommentModal({ isOpen, setOpen, column, setComment, initialComment }) {
  const [input, setInput] = useState(initialComment);

  const closeHandler = () => {
    setInput(initialComment);
    setOpen(false);
  };

  const inputChangeHandler = (e) => {
    setInput(e.target.value);
  };

  const okHandler = () => {
    setComment(column, input);
    setOpen(false);
  };

  const handleFocus = useCallback((input) => {
    input?.focus({
      cursor: "end",
    });
  }, []);

  return (
    <Modal
      open={isOpen}
      onCancel={closeHandler}
      title={column === COLUMN.Commentary ? "Add Comment" : "Add Project"}
      onOk={okHandler}
      closable={false}
    >
      <TextArea
        value={input}
        onChange={inputChangeHandler}
        rows={5}
        showCount
        ref={handleFocus}
        count={{
          max: 5000,
          show: true,
          exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(""),
        }}
      />
    </Modal>
  );
}
