import React, { memo } from "react";
import Logo from "../assets/dupont_logo.png";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import SignOutButton from "./SignOutButton";

function Header() {
  const {
    auth,
    auth: { isAuthenticated, authInProgress },
  } = useSelector((state) => state);
  return (
    <header className="bg-red-600 h-14 text-white flex flex-row justify-between items-center px-6 sticky top-0 left-0 right-0 z-50">
      <h2>
        NPS&nbsp;
        <span className="bold">Tagging</span>
      </h2>
      <div className="flex flex-row items-center gap-4">
        {auth && auth.user && (
          <span className="flex flex-row gap-2 items-center">
            <UserOutlined />
            {auth?.user?.fullname}
          </span>
        )}
        {!authInProgress || isAuthenticated
          ? isAuthenticated && (
              <SignOutButton
                type="text"
                style={{
                  color: "white",
                }}
              />
            )
          : null}
        <img alt="logo" src={Logo} className="bg-white h-8" />
      </div>
    </header>
  );
}

export default memo(Header);
