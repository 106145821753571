import Guidelines from "../pages/Guidelines";
import NPSDashboard from "../pages/NPSDashboard";
import SalesForce from "../pages/SalesForce";
import { Page } from "../pages/NewPage";
import { pages } from "../hooks/usePage.js";

export const routeArray = [
  { path: "/guidelines", component: <Guidelines /> },
  { path: "/salesforce", component: <SalesForce /> },
  { path: "/laird", component: <Page business={pages.laird} /> },
  { path: "/spectrum", component: <Page business={pages.spectrum} /> },
  { path: "/", component: <NPSDashboard /> },
  { path: "/*", component: <NPSDashboard /> },
];
