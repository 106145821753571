import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import React from "react";
import { signIn } from "../utils/signInActions";
import SignInButton from "./signInButton";

function Login() {
  return (
    <div className="w-full h-[80vh] flex flex-row justify-center items-center">
      <div className="h-min">
        <h3>You are not authenticated</h3>
        <h3>
          Please <SignInButton />
        </h3>
      </div>
    </div>
  );
}

export default Login;
