import React from "react";

function GuidelineSection({ heading, url, children }) {
  return (
    <div className="mb-12">
      <h3 className="mb-3">{heading} </h3>
      <p>{children} </p>
      {url ? (
        <a href={url} alt="url">
          {url}
        </a>
      ) : null}
    </div>
  );
}

export default GuidelineSection;
