import React, { useContext, useEffect } from "react";
import DHeader from "../../../components/Dashboard/DHeader";
import { AppError, ErrorPage } from "../../../components/ErrorPage";
import Loading from "../../../components/Loading";
import { AppContext, TableFilterContext } from "../../../App";
import { useSelector } from "react-redux";
import { getFilterCookies } from "../../../utils/cookies";
import { columnCategories } from "../../../utils/columns";
import FinalTable from "../../../components/FinalTable";
import { setUpdatesOne } from "../../../redux/actions/appActions";
import { setScrollSync } from "../../../utils/scrollSync";

function ProductTable() {
  const { columns, data, duplicateData, updatesOne, isProductLoading } = useSelector((state) => state.app);
  const { appError } = useSelector((state) => state.app);
  const filterCookies = getFilterCookies();
  const { tableRef, setTableRef, npsTableRef, setNPSTableRef } = useContext(TableFilterContext);
  const { rowsToBeUpdated, setRowsToBeUpdated, pageSize, currentPage, setCurrentPage, setFilterDrawerOpen } = useContext(AppContext);

  useEffect(() => {
    setScrollSync(1);
  }, [data, columns]);

  if (appError) return <AppError />;
  if (isProductLoading) return <Loading />;
  return (
    <div className="w-full px-10 mb-20" style={{ marginTop: 0 }}>
      <DHeader npsTableRef={npsTableRef} tableRef={tableRef} />
      {filterCookies && duplicateData && duplicateData.length ? (
        <>
          <div className="w-full bg-white items-center shadow-xl">
            <div className="flex flex-row w-full bg-white items-center shadow-xl px-5 pt-2">
              <h2 className="text-[1.1rem] w-[54vw]">{columnCategories.PRODUCT_DATA}</h2>
              <h2 className="text-[1.1rem] ">{columnCategories.NPS_TAGGING_DATA}</h2>
            </div>
            <FinalTable
              data={data}
              columns={columns[columnCategories.PRODUCT_DATA]}
              npsColumns={columns[columnCategories.NPS_TAGGING_DATA]}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              currentPage={currentPage}
              setRowsToBeUpdated={setRowsToBeUpdated}
              rowsToBeUpdated={rowsToBeUpdated}
              updates={updatesOne}
              setUpdates={setUpdatesOne}
              table={1} // this is table id, set to one for product data table and set to 2 for prod/customer data
              setTableRef={setTableRef}
              tableRef={tableRef}
              npsTableRef={npsTableRef}
              setNpsTableRef={setNPSTableRef}
            />
          </div>
        </>
      ) : (
        <ErrorPage setFilterDrawerOpen={setFilterDrawerOpen} />
      )}
    </div>
  );
}

export default ProductTable;
