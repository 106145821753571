import { createContext, useState } from "react";
import { getFilterCookies } from "../utils/cookies";

export const FilterContext = createContext();

export function FilterContextProvider({ children }) {
  const cachedFilters = getFilterCookies();
  const [businessSelected, setBusinessSelected] = useState(cachedFilters?.hier2);
  const [lobSelected, setLobSelected] = useState(cachedFilters?.hier3);
  const [reportingUnitSelected, setReportingUnitSelected] = useState(cachedFilters?.hier5);
  const [dataSourceSelected, setDataSourceSelected] = useState(cachedFilters?.dataSource || "SAP");
  return (
    <FilterContext.Provider
      value={{
        businessSelected,
        setBusinessSelected,
        lobSelected,
        setLobSelected,
        reportingUnitSelected,
        setReportingUnitSelected,
        dataSourceSelected,
        setDataSourceSelected,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
