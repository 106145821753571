import { Button, Select, TreeSelect } from "antd";
import { getMonthOptions, getSelectedGmidMonths, getYearOptions } from "../../../utils/monthUtils";
import useFilterUtils from "../../../hooks/useFilterUtils";
import { usePage } from "../../../hooks/usePage";
import { useDispatch, useSelector } from "react-redux";
import { getColumnFilterCookies, getFilterCookies } from "../../../utils/cookies";
import { AppContext, TableFilterContext } from "../../../App";
import { useCallback, useContext, useState } from "react";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import useTagging from "../../../hooks/useTagging";
import { updateUserSelection } from "../../../redux/actions/userSelectionActions";
import useDebounce from "../../../hooks/useDebounce";
import useData from "../../../hooks/useData";
import { setAppError } from "../../../redux/actions/appActions";
import { DEFAULT_MESSAGE_DURATION, LOADING_MESSAGE_DURATION } from "../../../constants/global.const";

export const ApplicationHeader = () => {
  const yearOptions = getYearOptions();
  const page = usePage();
  const { clearApplicationFilters } = useFilterUtils(page);
  const { submitTagging } = useTagging(page);
  const { duplicateApplicationData } = useSelector((state) => state.app);
  const { applicationSalesYear, applicationSalesMonth } = useSelector((state) => state.selections);
  const { tableRefTwo, npsTableRefTwo } = useContext(TableFilterContext);
  const { currentPageTwo, messageApi } = useContext(AppContext);
  const [salesMonth, setSalesMonth] = useState(applicationSalesMonth);
  const [salesYear, setSalesYear] = useState(applicationSalesYear);
  const monthOptions = getMonthOptions();
  const dispatch = useDispatch();
  const { debounce, lastTimeout } = useDebounce();
  const { getApplicationData } = useData(page);

  const applyFilters = useCallback(
    async (salesyear, salesmonth) => {
      messageApi.open({
        type: "loading",
        content: "Applying filters, please wait",
        duration: LOADING_MESSAGE_DURATION,
        key: "filters",
      });
      const cachedFilters = getFilterCookies();
      await getApplicationData({
        ...cachedFilters,
        salesyear,
        salesmonth,
      })
        .then((res) => {
          messageApi.open({
            key: "filters",
            type: "success",
            duration: DEFAULT_MESSAGE_DURATION,
            content: "Filters applied",
          });
        })
        .catch((err) => {
          messageApi.open({
            key: "filters",
            type: "error",
            duration: DEFAULT_MESSAGE_DURATION,
            content: err.message,
          });
          dispatch(setAppError(true));
        });
    },
    [dispatch, getApplicationData, messageApi]
  );

  const onChangeHandler = useCallback(
    (year = salesYear, month = salesMonth) => {
      if (!month || month?.length === 0) return;
      if (lastTimeout) clearTimeout(lastTimeout);
      if (lastTimeout) clearTimeout(lastTimeout);
      debounce(() => applyFilters(year, month), 1500);
    },
    [applyFilters, debounce, lastTimeout, salesMonth, salesYear]
  );

  const salesMonthChangeHandler = useCallback(
    (month) => {
      const _month = getSelectedGmidMonths(month);
      setSalesMonth(_month);
      dispatch(updateUserSelection({ applicationSalesMonth: _month }));
      onChangeHandler(salesYear, _month, salesYear);
    },
    [dispatch, onChangeHandler, salesYear]
  );

  const salesYearChangeHandler = useCallback(
    (year) => {
      setSalesYear(year);
      dispatch(updateUserSelection({ applicationSalesYear: year }));
      onChangeHandler(year, salesMonth);
    },
    [dispatch, onChangeHandler, salesMonth]
  );

  return (
    <div className="flex flex-row justify-between items-center mb-3">
      <span className="text-red-500 font-normal text-3xl ml-5">NPS Evaluation</span>
      <div className="flex gap-2 items-center">
        <span>Sales Year</span>
        <Select
          style={{ width: 100 }}
          placeholder="Sales year"
          options={yearOptions.slice(0, 2)}
          value={applicationSalesYear}
          onChange={salesYearChangeHandler}
        />
        <span>Sales Month</span>
        <TreeSelect
          style={{ width: 137, height: 30, overflow: "clip" }}
          placeholder="Month"
          value={salesMonth}
          treeData={monthOptions}
          status={salesMonth.length === 0 ? "error" : null}
          treeCheckable
          treeDefaultExpandAll
          treeIcon={true}
          onChange={salesMonthChangeHandler}
        />
      </div>
      <div className="flex flex-row gap-2">
        <Button
          onClick={() => clearApplicationFilters(tableRefTwo, npsTableRefTwo, duplicateApplicationData, currentPageTwo)}
          type="default"
          icon={<DeleteOutlined />}
          disabled={!getColumnFilterCookies(2)}
        >
          Clear Filters
        </Button>
        <Button
          onClick={() => {
            submitTagging();
          }}
          type="default"
          icon={<CheckOutlined />}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
