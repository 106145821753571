import { Checkbox, Drawer } from "antd";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnCategories } from "../utils/columns";
import { updateColumns } from "../redux/actions/appActions";
import { updateColumnCookies } from "../utils/cookies";
import useAggregation from "../hooks/useAggregations";
import { pages, usePage } from "../hooks/usePage";
import { TABS_KEYS } from "../pages/NPSDashboard/components/DashboardBody";

const SectionRenderer = ({ columns, category, handleCheck, selectAllHandler }) => {
  return (
    <>
      <span className="bg-gray-100 p-2 flex flex-row justify-between">
        <span>{category} </span>
        <span className="text-[#57B2FC] cursor-pointer" onClick={() => selectAllHandler(category)}>
          Select All
        </span>
      </span>
      {columns.map((col) => {
        if (col.dataIndex === "select")
          return (
            <Checkbox className="w-full" checked={true} key={col.dataIndex} disabled={true}>
              {col.title}
            </Checkbox>
          );
        return (
          <Checkbox className="w-full" checked={col.visibility} key={col.dataIndex} onClick={() => handleCheck(category, col.dataIndex)}>
            {col.title}
          </Checkbox>
        );
      })}
    </>
  );
};

function HorizontalSections({ section1, section2 }) {
  return (
    <div className="flex flex-row justify-between w-full gap-x-2">
      <div className="flex flex-col w-1/2">{section1}</div>
      <div className="flex flex-col w-1/2">{section2}</div>
    </div>
  );
}

function SideBar({ isOpen, setOpen, activeTab }) {
  const page = usePage();
  const { columns } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { hasAccessToApp } = useSelector((state) => state.app);
  const { aggregateData } = useAggregation(page);

  const handleCheck = useCallback(
    (category, columnIndex) => {
      let entireColumns = columns;
      let idx = columns[category].findIndex((col) => col.dataIndex === columnIndex);
      entireColumns[category][idx].visibility = !entireColumns[category][idx].visibility;
      dispatch(updateColumns(JSON.parse(JSON.stringify(entireColumns))));
      updateColumnCookies(entireColumns);
      aggregateData(entireColumns[category], category);
    },
    [aggregateData, columns, dispatch]
  );

  const areAllSelected = useCallback(
    (category) => {
      let flag = true;
      columns[category].forEach((col) => {
        flag &= col.visibility;
      });
      return flag;
    },
    [columns]
  );

  const selectAllHandler = (category) => {
    let allSelected = areAllSelected(category);
    let subColumns = columns[category].map((col) => ({
      ...col,
      visibility: col.dataIndex === "select" ? true : !allSelected,
    }));
    let newColumns = columns;
    newColumns[category] = subColumns;
    dispatch(updateColumns(newColumns));
    updateColumnCookies(newColumns);
    aggregateData(newColumns[category], category);
  };

  return (
    <div className="mt-20 fixed">
      <Drawer
        open={isOpen && hasAccessToApp}
        onClose={() => setOpen(false)}
        placement="left"
        width={550}
        closeIcon={false}
        height={100}
        mask={true}
        style={{ margin: 0, padding: 0 }}
        rootClassName="column-sidebar"
      >
        {columns &&
          (page === pages.SAP ? (
            activeTab === TABS_KEYS.PRODUCT ? (
              <HorizontalSections
                section1={
                  <SectionRenderer
                    columns={columns[columnCategories.PRODUCT_DATA]}
                    category={columnCategories.PRODUCT_DATA}
                    handleCheck={handleCheck}
                    selectAllHandler={selectAllHandler}
                  />
                }
                section2={
                  <SectionRenderer
                    columns={columns[columnCategories.NPS_TAGGING_DATA]}
                    category={columnCategories.NPS_TAGGING_DATA}
                    handleCheck={handleCheck}
                    selectAllHandler={selectAllHandler}
                  />
                }
              />
            ) : (
              <HorizontalSections
                section1={
                  <SectionRenderer
                    columns={columns[columnCategories.PRODUCT_CUSTOMER_DATA]}
                    category={columnCategories.PRODUCT_CUSTOMER_DATA}
                    handleCheck={handleCheck}
                    selectAllHandler={selectAllHandler}
                  />
                }
                section2={
                  <SectionRenderer
                    columns={columns[columnCategories.APPLICATION_TAGGING]}
                    category={columnCategories.APPLICATION_TAGGING}
                    handleCheck={handleCheck}
                    selectAllHandler={selectAllHandler}
                  />
                }
              />
            )
          ) : page === pages.salesforce ? (
            <HorizontalSections
              section1={
                <SectionRenderer
                  columns={columns[columnCategories.SFDC_COLUMNS]}
                  category={columnCategories.SFDC_COLUMNS}
                  handleCheck={handleCheck}
                  selectAllHandler={selectAllHandler}
                />
              }
              section2={
                <SectionRenderer
                  columns={columns[columnCategories.APPLICATION_TAGGING]}
                  category={columnCategories.APPLICATION_TAGGING}
                  handleCheck={handleCheck}
                  selectAllHandler={selectAllHandler}
                />
              }
            />
          ) : page === pages.laird ? (
            <HorizontalSections
              section1={
                <SectionRenderer
                  columns={columns[columnCategories.LAIRD]}
                  category={columnCategories.LAIRD}
                  handleCheck={handleCheck}
                  selectAllHandler={selectAllHandler}
                />
              }
              section2={
                <SectionRenderer
                  columns={columns[columnCategories.NPS_TAGGING_DATA]}
                  category={columnCategories.NPS_TAGGING_DATA}
                  handleCheck={handleCheck}
                  selectAllHandler={selectAllHandler}
                />
              }
            />
          ) : page === pages.spectrum ? (
            <HorizontalSections
              section1={
                <SectionRenderer
                  columns={columns[columnCategories.SPECTRUM]}
                  category={columnCategories.SPECTRUM}
                  handleCheck={handleCheck}
                  selectAllHandler={selectAllHandler}
                />
              }
              section2={
                <SectionRenderer
                  columns={columns[columnCategories.NPS_TAGGING_DATA]}
                  category={columnCategories.NPS_TAGGING_DATA}
                  handleCheck={handleCheck}
                  selectAllHandler={selectAllHandler}
                />
              }
            />
          ) : null)}
      </Drawer>
    </div>
  );
}

export default memo(SideBar);
